import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import '../i18n.js';

export default function Clanstvo() {
    const { t } = useTranslation(); 
    
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null); // Drži poruke o greškama

    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        membershipType: '',
        file: null,
        aboutMe: ''
    });

    const handleChange = (e) => {
        const { id, value, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: files ? files[0] : value 
        }));
    };

   
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

   
    const isValidFileSize = (file) => {
        const maxSize = 5 * 1024 * 1024; // 5MB
        return file.size <= maxSize;
    };

    function Registration(e) {
        e.preventDefault(); 

        // Resetuj poruku o grešci
        setErrorMessage(null);

       
        if (!isValidEmail(formData.email)) {
            setErrorMessage(t('Neispravna email adresa.'));
            return;
        }

        // Proveri veličinu fajla
        if (formData.file && !isValidFileSize(formData.file)) {
            setErrorMessage(t('Fajl je prevelik. Maksimalna veličina je 5MB.'));
            return;
        }

        // Ako je validacija prošla, formiraj podatke za slanje
        const data = new FormData();
        data.append('name', formData.name);
        data.append('surname', formData.surname);
        data.append('email', formData.email);
        data.append('phone', formData.phone);
        data.append('membershipType', formData.membershipType);
        data.append('file', formData.file);
        data.append('aboutMe', formData.aboutMe);

        fetch('backend.php', {
            method: 'POST',
            body: data
        })
        .then(response => response.ok ? response.json() : Promise.reject('Invalid response format'))
        .then(result => {
            console.log('Success:', result);
            setIsSubmitted(true);
        })
        .catch(error => {
            console.error('Error:', error);
            setErrorMessage(t('Došlo je do greške. Pokušajte ponovo.'));
        });
    }

    return (
        <>
       <Helmet>
        <title>{t('Postani član - ASK Bosna')}</title>
        <link rel="canonical" href={`https://askbosna.ba/postani-clan`} />
        <meta name="description" content="Informacije o pridruživanju našem timu." />
        <meta name="keywords" content="Airsoft, Klub, Bosna, registracija" />
       </Helmet>

        <main className={isSubmitted ? "main--submitted": ""}>
            {isSubmitted ? (
                <div>
                    <p>{t('Hvala na prijavi')}!</p>
                    <p>{t('Uspešno ste poslali zahtev')}.</p> 
                    <p>{t('Uskoro ćemo vas kontaktirati')}.</p>
                </div>
            ) : (
                <div>
                    <h1 className="title">{t('Pridružite se našem timu')}</h1>


                    <form className="form--registration" onSubmit={Registration} encType="multipart/form-data">
                        <label>{t('Želim se prijaviti kao')}...
                            <select id="membershipType" value={formData.membershipType} onChange={handleChange} required>
                                <option value="" disabled>--{t('Odaberi tip članstva')}--</option>
                                <option value="redovan">{t('redovan igrač')}</option>
                                <option value="povremeni">{t('povremeni igrač')}</option>
                            </select>
                        </label>
                         
                        <label>{t('Ime')}
                            <input 
                                type="text"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder={t('Ime')}
                                className="input--registration"
                                autocomplete
                                required
                            />
                        </label>

                        <label>{t('Prezime')}
                            <input 
                                type="text"
                                id="surname"
                                value={formData.surname}
                                onChange={handleChange}
                                placeholder={t('Prezime')}
                                className="input--registration"
                                autocomplete
                                required
                            />
                        </label>

                        <label>{t('E-mail')}
                            <input 
                                type="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder={t('korisnik@mail.com')}
                                className="input--registration"
                                autocomplete
                                required
                            />
                        </label>

                        <label>{t('Telefon')}
                            <input 
                                type="text"
                                id="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder={t('0671234567')}
                                className="input--registration"
                                autocomplete
                                required
                            />
                        </label>

                        <label>{t('Priloženi dokument o nekažnjavanju')} <span className="formats">format: jpeg/jpg/png/pdf (max 5MB)</span>
                            <input 
                                type="file"
                                id="file"
                                onChange={handleChange}
                                accept=".jpeg,.jpg,.png,.pdf"
                                required
                            />
                         

                        </label>

                        <label>{t('Više o meni')}
                            <textarea 
                                id="aboutMe"
                                value={formData.aboutMe}
                                onChange={handleChange}
                                placeholder={t('')}
                            />
                        </label>         
                        {errorMessage && (
                                  <p style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</p>  
                             )}
                        <button type="submit" className="button--registration">{t('Registruj se')}</button>
                    </form>
                </div>
            )}
        </main>
        </>
    );
}

/*import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import '../i18n.js';

export default function Clanstvo() {
    const { t } = useTranslation(); 
    
    const [isSubmitted, setIsSubmitted] = useState(false);
    
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        membershipType: '',
        file: null,
        aboutMe: ''
    });

   
    const handleChange = (e) => {
        const { id, value, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: files ? files[0] : value // Ako je fajl input, uzmi prvi fajl, inače uzmi vrednost
        }));
    };

    // Funkcija koja se izvršava na klik dugmeta za registraciju
    function Registration(e) {
        e.preventDefault(); 

        
        const data = new FormData();
        data.append('name', formData.name);
        data.append('surname', formData.surname);
        data.append('email', formData.email);
        data.append('phone', formData.phone);
        data.append('membershipType', formData.membershipType);
        data.append('file', formData.file); // Fajl
        data.append('aboutMe', formData.aboutMe);

   
   
           fetch('../private/backend.php', {
            method: 'POST',
            body: data
        })
        .then(response => response.ok ? response.json() : Promise.reject('Invalid response format'))
        .then(result => {
            console.log('Success:', result);
            setIsSubmitted(true);
        })
        .catch(error => {
            
            console.error('Error:', error);
            alert('Došlo je do greške. Pokušajte ponovo.');
        });


    }



    return (
        <>

       <Helmet>
        <title>{t('Postani član - ASK Bosna')}</title>
        <meta name="description" content="Informacije o pridruživanju našem timu." />
        <meta name="keywords" content="Airsoft, Klub, Bosna, registracija" />
       </Helmet>


        <main className={isSubmitted ? "main--submitted": ""}>
            <div className={isSubmitted ? "" : "hidden"}>
                <p>{t('Hvala na prijavi')}!</p>
                <p>{t('Uspešno ste poslali zahtev')}.</p> 
                <p>{t('Uskoro ćemo vas kontaktirati')}.</p>
            </div>
        <div className={isSubmitted ? "hidden": ""}>
            <h1 className="title">{t('Pridružite se našem timu')}</h1>
            <form className="form--registration" onSubmit={Registration} encType="multipart/form-data">
                <label>{t('Želim se prijaviti kao')}...
                    <select id="membershipType" value={formData.membershipType} onChange={handleChange} required>
                        <option value="" disabled selected>-- {t('Odaberi tip članstva')} --</option>
                        <option value="redovan">{t('redovan igrač')}</option>
                        <option value="povremeni">{t('povremeni igrač')}</option>

                    </select>
                </label>
                 
                <label>{t('Ime')}
                    <input 
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder={t('Ime')}
                        className="input--registration"
                        required
                    />
                </label>

                <label>{t('Prezime')}
                    <input 
                        type="text"
                        id="surname"
                        value={formData.surname}
                        onChange={handleChange}
                        placeholder={t('Prezime')}
                        className="input--registration"
                        required
                    />
                </label>

                <label>{t('E-mail')}
                    <input 
                        type="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={t('korisnik@mail.com')}
                        className="input--registration"
                        required
                    />
                </label>

                <label>{t('Telefon')}
                    <input 
                        type="text"
                        id="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder={t('0671234567')}
                        className="input--registration"
                        required
                    />
                </label>

                <label>{t('Priloženi dokument o nekažnjavanju')} <span className="formats">format: jpeg/jpg/png/pdf (max 5MB)</span>
                    <input 
                        type="file"
                        id="file"
                        onChange={handleChange}
                        accept=".jpeg,.jpg,.png,.pdf"
                        required
                    />
                </label>

                <label>{t('Više o meni')}
                    <textarea 
                        id="aboutMe"
                        value={formData.aboutMe}
                        onChange={handleChange}
                        placeholder={t('')}
                    />
                </label>         

                <button type="submit" className="button--registration">{t('Registruj se')}</button>
            </form>
        </div>
        </main>

        </>
    );
}*/
