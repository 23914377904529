import React, { useState, useEffect, useRef } from 'react';
import './FotoVideo.css';

export default function MediaScroll({ contentType, pauseMusic, playMusic }) {
  const [isPaused, setIsPaused] = useState(false);
  const [media, setMedia] = useState([]);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null); 
  const videoRef = useRef([]);  // Referenca za video elemente
  const [isDragging, setIsDragging] = useState(false);
  const scrollRef = useRef(null);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const dragStartX = useRef(0); 
  const [loadedVideos, setLoadedVideos] = useState([]);  // Sadržaj koji je učitan

  const handleMouseDown = (e) => {
    if (contentType === 'video') {
      setIsDragging(false);  
      dragStartX.current = e.pageX;  
      startX.current = e.pageX - scrollRef.current.offsetLeft;
      scrollLeft.current = scrollRef.current.scrollLeft;
    }
  };

  const handleMouseMove = (e) => {
    if (contentType === 'video') {
      const x = e.pageX - scrollRef.current.offsetLeft;
      const walk = (x - startX.current) * 2; 
      scrollRef.current.scrollLeft = scrollLeft.current - walk;

      if (Math.abs(e.pageX - dragStartX.current) > 5) {
        setIsDragging(true);  
      }
    }
  };

  const handleMouseUp = () => {
    setTimeout(() => setIsDragging(false), 50); 
  };

  const handleMediaClick = (index) => {
    if (!isDragging) {
      setCurrentIndex(index);
    }
  };

  const closeModal = () => {
    setCurrentIndex(null);
  };

  const showNextMedia = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
  };

  const showPreviousMedia = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + media.length) % media.length);
  };

 
  useEffect(() => {
    const apiPath = contentType === 'image' ? '/api/images' : '/api/videos';
    fetch(apiPath)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        /*console.log("Fetched data: ", data);*/
        setMedia(data);
        document.documentElement.style.setProperty('--animation-duration', `${data.length * 4}s`);
      })
      .catch(error => {
        console.error(`Error fetching ${contentType}s:`, error);
        /*setError(`Failed to load ${contentType}s`);*/
      });
  }, [contentType]);

  // Lazy loading
  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const videoIndex = entry.target.getAttribute('data-index');
          setLoadedVideos(prevLoaded => [...prevLoaded, videoIndex]);
          observer.unobserve(entry.target); // Prestanak posmatranja kada je video učitan
        }
      });
    });

    if (contentType === 'video') {
      videoRef.current.forEach((videoEl, index) => {
        if (videoEl) {
          observer.observe(videoEl);  // Posmatranje svakog videa
        }
      });
    }

    return () => {
      if (contentType === 'video') {
        videoRef.current.forEach(videoEl => {
          if (videoEl) {
            observer.unobserve(videoEl); // Čišćenje posmatranja
          }
        });
      }
    };
  }, [media, contentType]);

  return (
    <div className={`media--container ${isPaused ? 'paused' : ''} ${contentType === 'video' ? 'video--style' : 'image--style'}`}>
      <div 
        className={`${contentType === 'video' ? "video" : "image"}--wrapper`}
        ref={scrollRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseEnter={() => setIsPaused(true)}   
        onMouseLeave={() => setIsPaused(false)}
      >
        {error && <p className="error">{error}</p>}
        {media.length === 0 ? (
          console.error("No media")
        ) : (
          media.map((mediaItem, index) => (
            contentType === 'image' ? (
              <img
                key={mediaItem}
                src={`/sadrzaj/galerija/${mediaItem}`}
                alt={mediaItem}
                onClick={() => handleMediaClick(index)}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'https://gunwildfire.com/wp-content/uploads/2017/08/airsoft-1024x634.jpg';
                }}
              />
            ) : (
              <video
                key={mediaItem}
                ref={(el) => (videoRef.current[index] = el)}
                data-index={index}
                src={loadedVideos.includes(`${index}`) ? `/sadrzaj/video/${mediaItem}` : ''} // Učitava samo kada je u vidnom polju
                controls
                onClick={() => handleMediaClick(index)}
                onPlay={pauseMusic} 
                onPause={playMusic} 
              />
            )
          ))
        )}
      </div>

      {/* Modal */}
      {currentIndex !== null && (
        <div className="modal" onClick={(e) => {
          if (e.target.className === 'modal') {
            closeModal();
          }
        }}>
          <span className="close" onClick={closeModal}>&times;</span>
          {contentType === 'image' ? (
            <img className="modal-content" src={`/sadrzaj/galerija/${media[currentIndex]}`} alt="" />
          ) : (
            <video
              className="modal-content"
              src={`/sadrzaj/video/${media[currentIndex]}`}
              controls
              autoPlay
              onPlay={pauseMusic} 
              onPause={playMusic} 
            />
          )}
          <div className="modal-navigation">
            <button onClick={showPreviousMedia}>&lt;</button>
            <button onClick={showNextMedia}>&gt;</button>
          </div>
        </div>
      )}
    </div>
  );
}
