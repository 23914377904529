import React, {useState, useEffect} from 'react';
import SmokeBackground from './Smoke.js';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import i18n from 'i18next';
import '../i18n.js';

export default function Donacija() {
    const { t } = useTranslation();

    const [content, setContent] = useState({ paragraphs: [] });

    const [sponzori, setSponzori] = useState([]);

    const [scrollY, setScrollY] = useState(0);  // Praćenje skrola

    // Funkcija za praćenje skrola
    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    // Ažuriraj visinu prozora ako korisnik promeni veličinu prozora
         useEffect(() => {
           const handleResize = () => {
           setViewportHeight(window.innerHeight);
         };

         window.addEventListener('resize', handleResize);
         return () => window.removeEventListener('resize', handleResize);
         }, []);
    
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const currentLanguage = i18n.language;
        fetch(`/sadrzaj/tekst/donacija/donacija-${currentLanguage}.json`)
          .then((response) => response.json())
          .then((data) => setContent(data))
          .catch((error) => console.error('Error fetching JSON:', error));
    }, [i18n.language]);

    useEffect(() => {
        fetch('/sadrzaj/sponzori/sponzori.json')
          .then((response) => response.json())
          .then((data) => setSponzori(data))
          .catch((error) => console.error('Error fetching sponsor data', error))
    }, []);

    const cloudStyle1 = {
        transform: `translateY(-${scrollY * 1}px)`, 
        opacity: scrollY < viewportHeight ? 1 : 0,               
        transition: 'opacity 1.5s ease-out',          
    };
    const cloudStyle2 = {
        transform: `translateY(-${scrollY * 1.2}px)`, 
        opacity: scrollY < viewportHeight ? 1 : 0,               
        transition: 'opacity 2s ease-out',          
    };
    const cloudStyle3 = {
        transform: `translateY(-${scrollY * 1.3}px)`, 
        opacity: scrollY < viewportHeight ? 1 : 0,               
        transition: 'opacity 2.5s ease-out',          
    };

    const sponsorsDivStyle = {
        position: scrollY < viewportHeight ? 'fixed' : 'relative', 
        top: scrollY < viewportHeight/2 ? '0' : 'auto',  
        opacity: scrollY < viewportHeight/2 ? 1 : 0,         
        left: 0,
        right: 0,
        textAlign: 'center',  
        zIndex: 2,           
        transition: 'position 0.5s ease-out, opacity 0.5s ease-out' , 
    };

    const smokeBackgroundStyle = {
        position: 'fixed',   
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',     
        zIndex: 1,          
        opacity: scrollY < 700 ? 1 : 0,  
        transition: 'opacity 0.5s ease-out',
        pointerEvents: 'none'  
    }
    
    return (

    <>

        <Helmet>
        <title>{t('Sponzori - ASK Bosna')}</title>
        <link rel="canonical" href={`https://askbosna.ba/sponzori`} />
        <meta name="description" content="Informacije o donacijama našem udruženju." />
        <meta name="keywords" content="Airsoft, Klub, Bosna, donacija" />
        </Helmet>


        <main className="main--sponsors">
           <div style={smokeBackgroundStyle}>
                  <SmokeBackground />
                </div>

            <div className="scroll--clouds">
               
               <div className="sponsors--div" style={sponsorsDivStyle}>
               <h1 className="title stitle">{t('Sponzori')}</h1>
                 <div className="sponsor--container">
                    {sponzori.length > 0 && sponzori.map((sponzor) => {
                        return (
                            <div className="sponsor" key={sponzor.id}>
                                <a href={sponzor.link}>
                                 <img src={sponzor.logo} alt="logo"/>
                                </a>
                                <h3>{sponzor.ime}</h3>
                            </div>
                        )
                    }) }
                 </div>
                  <a href="#1" className="arrow-container">
                     <div className="arrow"></div>
                     <div className="arrow"></div>
                     <div className="arrow"></div>  
                   </a>
                </div>
            </div>

            <div className="div--split--clouds layer1" style={cloudStyle1}></div>
            <div className="div--split--clouds layer2" style={cloudStyle2}></div>
            <div className="div--split--clouds layer3" style={cloudStyle3}></div>

            <div className="donacija--div" id="1">
            <h1 className="title">{t('Donacija')}</h1>
              <div className="donacija--opis">
             {content.paragraphs.map((paragraph, index) => (
            <p key={index}>{(paragraph)}</p>
          ))}
             </div>
             <SmokeBackground />
            </div>
        </main>

    </>
    );
}
