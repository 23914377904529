/*import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const flagImages = {
  bih: '/images/bih.png',
  srb: '/images/srb.png',
  uk: '/images/uk.png',
  ger: '/images/ger.png'
};



const FlagSelector = () => {
  const [selectedFlag, setSelectedFlag] = useState('bih'); 

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); 
    }
  }, [i18n]);

  const handleChange = (e) => {

   
    const selectedValue = e.target.value;
    setSelectedFlag(selectedValue); 
    switch (selectedValue) {
      case 'bih':
        i18n.changeLanguage('bs');
        break;
      case 'srb':
        i18n.changeLanguage('sr');
        break;
      case 'uk':
        i18n.changeLanguage('en');
        break;
      case 'ger':
        i18n.changeLanguage('de');
        break;
      default:
        i18n.changeLanguage('bs');
    }
  };

  return (
    <div className="flag--container">
      <select className="flag--select" value={selectedFlag} onChange={handleChange}>
        <option value="bih">BiH</option>
        <option value="srb">SR</option>
        <option value="uk">UK</option>
        <option value="ger">DE</option>
      </select>

      <div className="flag">
        <img
          src={flagImages[selectedFlag]}
          alt={`${selectedFlag} flag`}  
        />
      </div>
    </div>
  );
};

export default FlagSelector;*/
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const flagImages = {
  bih: '/images/bih.png',
  srb: '/images/srb.png',
  uk: '/images/uk.png',
  ger: '/images/ger.png'
};

const FlagSelector = () => {
  const [selectedFlag, setSelectedFlag] = useState('bih'); // Default jezik
  const { i18n } = useTranslation();

  useEffect(() => {
    // Provera da li postoji sačuvan jezik u localStorage
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); 
      
      switch (savedLanguage) {
        case 'bs':
          setSelectedFlag('bih');
          break;
        case 'sr':
          setSelectedFlag('srb');
          break;
        case 'en':
          setSelectedFlag('uk');
          break;
        case 'de':
          setSelectedFlag('ger');
          break;
        default:
          setSelectedFlag('bih');
      }
    }
  }, [i18n]);

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedFlag(selectedValue); 

    // Promena jezika u i18n i čuvanje u localStorage
    let languageCode;
    switch (selectedValue) {
      case 'bih':
        languageCode = 'bs';
        break;
      case 'srb':
        languageCode = 'sr';
        break;
      case 'uk':
        languageCode = 'en';
        break;
      case 'ger':
        languageCode = 'de';
        break;
      default:
        languageCode = 'bs';
    }
    i18n.changeLanguage(languageCode);
    localStorage.setItem('language', languageCode); // Čuvanje izbora jezika u localStorage
  };

  return (
    <div className="flag--container">
      <select className="flag--select" value={selectedFlag} onChange={handleChange} id="flag" name="flag">
        <option value="bih">BiH</option>
        <option value="srb">SR</option>
        <option value="uk">UK</option>
        <option value="ger">DE</option>
      </select>

      <div className="flag">
        <img
          src={flagImages[selectedFlag]}
          alt={`${selectedFlag} flag`}  
        />
      </div>
    </div>
  );
};

export default FlagSelector;


