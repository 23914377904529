/*import React, { useState, useEffect } from 'react';
import Media from './FotoVideo.js';
import Scrolltext from './Scrolltext.js';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import i18n from 'i18next';
import '../i18n.js';

export default function Onama({ pauseMusic, playMusic }) {
    const { t } = useTranslation();

    const [content, setContent] = useState({ onamap: [] });
    const [contact, setContact] = useState([])
    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const currentLanguage = i18n.language;
        fetch(`/sadrzaj/tekst/o-nama/o-nama-${currentLanguage}.json`)
          .then((response) => response.json())
          .then((data) => setContent(data))
          .catch((error) => console.error('Error fetching JSON:', error));
    }, [i18n.language]);

    useEffect(() => {
      fetch(`/sadrzaj/tekst/o-nama/kontakt.json`)
        .then((response) => response.json())
        .then((data) => setContact(data))
        .catch((error) => console.error('Error fetching JSON:', error));
  }, );

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

// Ažuriraj visinu prozora ako korisnik promeni veličinu prozora
     useEffect(() => {
       const handleResize = () => {
       setViewportHeight(window.innerHeight);
     };

     window.addEventListener('resize', handleResize);
     return () => window.removeEventListener('resize', handleResize);
     }, []);


  const style1 = {
    position: 'absolute', 
    bottom: 0,
    left: 0,
    top: scrollY >= viewportHeight ? `${Math.min(scrollY - viewportHeight, 0)}px` : 'auto',
    position: scrollY >= viewportHeight ? 'fixed' : 'absolute',
    transform: scrollY < viewportHeight 
    ? `translateY(-${scrollY * 1}px)` 
    : `translateY(10%)`,
    zIndex: 3,    
};
const style2 = {
  position: scrollY >= viewportHeight/2 ? 'fixed' : 'absolute',
  bottom: 0,
  left: 0,
  transform: scrollY < viewportHeight/2 
  ? `translateY(-${scrollY * 1}px)` 
  : `translateY(-${(scrollY - viewportHeight/2) * 0.6}px)`,      
  zIndex: 4        
};
const style3 = {
  position: scrollY >= viewportHeight/2 ? 'fixed' : 'absolute', 
  bottom: 0,
  left: 0,
  transform: scrollY < viewportHeight/2 
  ? `translateY(-${scrollY * 1}px)` 
  : `translateY(-${(scrollY - viewportHeight/2) * 0.9}px)`,        
    zIndex: 5         
};

  const scrollParallax = {
    position: scrollY < 600 ? 'fixed' : 'relative',
    top: scrollY < 600 ? 0 : 'auto',
    zIndex: 2,
    textAlign: 'center'
  }
    
    return (
        <>
      <Helmet>
        <title>{t('O nama - ASK Bosna')}</title>
        <meta name="description" content="Informacije o našem timu i klubskim aktivnostima." />
        <meta name="keywords" content="Airsoft, Klub, Bosna, tim, planovi, kontakt" />
      </Helmet>
        <main className="o--nama--main">
          <div className="scroll--parallax">
          <div className="scrol--parallax" style={scrollParallax}>
            <h1 className="title">{t('O nama')}</h1>
            <div className="opis--o--nama">
            {content.onamap.map((paragraph, index) => (
            <p key={index}>{(paragraph)}</p>
          ))}
            </div>
          </div>
          </div>

            <div className="parallax">
                <div className="para1" style={style1}></div>
                <div className="para2" style={style2}></div>
                <div className="para3" style={style3}></div>
            </div>

           <div className="slike--div">
            <Media 
              contentType = 'image'
              />
            </div>

            <div className="div--split0"> 
                <Scrolltext />
            </div>
            <div className="second--bck"></div>
            <div className="div--split">

            <Media 
              contentType = 'video'
              pauseMusic={pauseMusic}
              playMusic={playMusic}
              />

            </div>
            <div className="third--bck"></div>
            <div className="div--split0">
               <div className="scroll--container">
                 <h2>{t('Kako do nas')}?</h2>
                 <iframe 
                     title="Google Maps"
                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d178.87461553009427!2d17.650661327555742!3d44.165986323241945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475f01cb60c8aeb7%3A0xb6f9b3fec3f78d4!2sMERKEZ%20OIL!5e0!3m2!1ssr!2srs!4v1729211848081&hl=bs" 
                     width="500" 
                     height="400" 
                     style={{border:0}} 
                     allowfullscreen 
                     loading="lazy" 
                     referrerpolicy="no-referrer-when-downgrade">
                </iframe>
               </div>
            </div>
            <div className="fourth--bck"></div>
            <footer className="contact">
                <div className="info">
                    <h1 style={{fontSize: '3em'}}>{t('Kontaktirajte nas')}</h1>
                    <div>
                        <p>{t('Zapratite nas')}:</p>
                        <a href="https://www.facebook.com/askbosna" target="_blank" rel="noreferrer"><img src="/images/facebook.svg" alt="fb-ico" className="soc--ico"/>&nbsp;</a>
                        <a href="https://instagram.com/askbosna" target="_blank" rel="noreferrer"><img src="/images/instagram.svg" alt="in-ico" className="soc--ico"/>&nbsp;</a>
                        <a href="https://tiktok.com" target="_blank" rel="noreferrer"><img src="/images/tiktok.svg" alt="tt-ico" className="soc--ico"/>&nbsp;</a>
                        <a href="https://discord.com" target="_blank" rel="noreferrer"><img src="/images/discord.svg" alt="dc-ico" className="soc--ico"/>&nbsp;</a>
                    </div>
                    <p>{t('E-mail adresa')}: {contact.email}</p>
                    <p>Viber/WhatsApp: {contact.viber}</p> 
                    <p>Mobile: {contact.mobile}</p>
                    <p>{contact.location}</p>
                    <p>{t('© 2024 Airsoft klub Bosna')}</p>
                </div>
                <img className="logo" src="/images/askbosna-logo.svg" onClick={() => window.location.href = '/'} alt="logo"/>
            </footer>
        </main>
        </>
    )
}*/

 
import React, { useState, useEffect } from 'react';
import Media from './FotoVideo.js';
import Scrolltext from './Scrolltext.js';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import i18n from 'i18next';
import '../i18n.js';

export default function Onama({ pauseMusic, playMusic }) {
    const { t } = useTranslation();

    const [content, setContent] = useState({ onamap: [] });
    const [contact, setContact] = useState([])

    useEffect(() => {
        const currentLanguage = i18n.language;
        fetch(`/sadrzaj/tekst/o-nama/o-nama-${currentLanguage}.json`)
          .then((response) => response.json())
          .then((data) => setContent(data))
          .catch((error) => console.error('Error fetching JSON:', error));
    }, [i18n.language]);

    useEffect(() => {
      fetch(`/sadrzaj/tekst/o-nama/kontakt.json`)
        .then((response) => response.json())
        .then((data) => setContact(data))
        .catch((error) => console.error('Error fetching JSON:', error));
  }, );
    
    return (
        <>
      <Helmet>
        <title>{t('O nama - ASK Bosna')}</title>
        <link rel="canonical" href={`https://askbosna.ba/o-nama`} />
        <meta name="description" content="Informacije o našem timu i klubskim aktivnostima." />
        <meta name="keywords" content="Airsoft, Klub, Bosna, tim, planovi, kontakt" />
      </Helmet>
        <main className="o--nama--main">
            <h1 className="title">{t('O nama')}</h1>
            <div className="opis--o--nama">
            {content.onamap.map((paragraph, index) => (
            <p key={index}>{(paragraph)}</p>
          ))}
            </div>

            <Media 
              contentType = 'image'
              />

            <div className="div--split0"> 
                <Scrolltext />
            </div>
            <div className="second--bck"></div>
            <div className="div--split">

            <Media 
              contentType = 'video'
              pauseMusic={pauseMusic}
              playMusic={playMusic}
              />

            </div>
            <div className="third--bck"></div>
            <div className="div--split0">
               <div className="scroll--container">
                 <h2>{t('Kako do nas')}?</h2>
                 <iframe 
                    src={contact.coords}
                    width="500" 
                    height="400" 
                    style={{border:0}} 
                    allowfullscreen 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                 </iframe>

               </div>
            </div>
            <div className="fourth--bck"></div>
            <footer className="contact" id="contact">
                <div className="info">
                    <h1 style={{fontSize: '3em'}}>{t('Kontaktirajte nas')}</h1>
                    <div>
                        <p>{t('Zapratite nas')}:</p>
                        <a href="https://www.facebook.com/askbosna" target="_blank" rel="noreferrer"><img src="/images/facebook.svg" alt="fb-ico" className="soc--ico"/>&nbsp;</a>
                        <a href="https://instagram.com/askbosna" target="_blank" rel="noreferrer"><img src="/images/instagram.svg" alt="in-ico" className="soc--ico"/>&nbsp;</a>
                       {/* <a href="https://tiktok.com" target="_blank" rel="noreferrer"><img src="/images/tiktok.svg" alt="tt-ico" className="soc--ico"/>&nbsp;</a>
                        <a href="https://discord.com" target="_blank" rel="noreferrer"><img src="/images/discord.svg" alt="dc-ico" className="soc--ico"/>&nbsp;</a> */}
                    </div>
                    <p>{t('E-mail adresa')}: {contact.email}</p>
                    {contact.viber !="" && <p>Viber/WhatsApp: {contact.viber}</p>}
                    {contact.mobile != "" && <p>Mobile: {contact.mobile}</p>}
                    <p>{contact.location}</p>
                    <p>{t('© 2024 Airsoft klub Bosna')}</p>
                </div>
                <img className="logo" src="/images/askbosna-logo.svg" onClick={() => window.location.href = '/'} alt="logo"/>
            </footer>
        </main>
        </>
    )
}

