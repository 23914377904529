import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n.js';

export default function Card(props) {
    const { t } = useTranslation();
    const cardRef = useRef(null);  // Kreiramo ref za karticu
    let bounds;

    useEffect(() => {
        const $card = cardRef.current;

        function rotateToMouse(e) {
            const mouseX = e.clientX;
            const mouseY = e.clientY;
            const leftX = mouseX - bounds.x;
            const topY = mouseY - bounds.y;
            const center = {
                x: leftX - bounds.width / 2,
                y: topY - bounds.height / 2
            };
            const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

            $card.style.transform = `
              scale3d(1.07, 1.07, 1.07)
              rotate3d(
                ${center.y / 100},
                ${-center.x / 100},
                0,
                ${Math.log(distance) * 2}deg
              )
            `;

            $card.querySelector('.glow').style.backgroundImage = `
              radial-gradient(
                circle at
                ${center.x * 2 + bounds.width / 2}px
                ${center.y * 2 + bounds.height / 2}px,
                #ffffff55,
                #0000000f
              )
            `;
        }

        const handleMouseEnter = () => {
            bounds = $card.getBoundingClientRect();
            document.addEventListener('mousemove', rotateToMouse);
        };

        const handleMouseLeave = () => {
            document.removeEventListener('mousemove', rotateToMouse);
            $card.style.transform = '';
            $card.querySelector('.glow').style.backgroundImage = '';
        };

        $card.addEventListener('mouseenter', handleMouseEnter);
        $card.addEventListener('mouseleave', handleMouseLeave);

        // Clean up when the component unmounts
        return () => {
            $card.removeEventListener('mouseenter', handleMouseEnter);
            $card.removeEventListener('mouseleave', handleMouseLeave);
            document.removeEventListener('mousemove', rotateToMouse);
        };
    }, []);  // Prazna zavisnost znači da se efekat pokreće samo jednom nakon mount-a

    return (
        <div className="card" ref={cardRef}>
            <div className="card--image">
                <img src={props.slika} alt={props.ime} />
            </div>
            <div className="card--text">
                <h3>{props.ime}</h3>
                <p>{t(`${props.status}`)}</p>
                <p>{t('Član od')}: {props.clan_od}</p>
            </div>    
            <div className="glow"></div> {/* Glow element za pozadinski efekat */}
        </div>
    );
}
