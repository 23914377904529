import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import "../index.css"; 

const ScrollText = () => {
  const { t } = useTranslation(); 
  const [isVisible, setIsVisible] = useState(false); 
  const textRef = useRef(null); 
  const [content, setContent] = useState({ nasiplanovip: [] });
  const [error, setError] = useState(null); // Dodato stanje za greške

  useEffect(() => {
    const currentLanguage = i18n.language;
    fetch(`/sadrzaj/tekst/o-nama/o-nama-${currentLanguage}.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setContent(data);
        setError(null); // Resetuj grešku ako je prethodno postojala
      })
      .catch((error) => {
        console.error('Error fetching JSON:', error);
        setError('Failed to load content');
      });
  }, [i18n.language]); // Dodana zavisnost na jezik

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true); 
            observer.unobserve(entry.target); 
          }
        });
      },
      {
        threshold: 0.8, 
      }
    );

    if (textRef.current) {
      observer.observe(textRef.current); 
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, []); // Ovaj useEffect ne treba zavisnosti osim na početku (mount)

  return (
    <div className="scroll--container">
      <h2
        ref={textRef}
        className={`hidden--title ${isVisible ? "visible" : ""}`} 
      >
        {t('Naši planovi')}
      </h2>
      
      {error ? ( // Provera greške
        <p>{error}</p>
      ) : (
        content.nasiplanovip.map((paragraph, index) => (
          <p className={`hidden--p${index} ${isVisible ? "visible" : ""}`} key={index}>
            {paragraph}
          </p>
        ))
      )}
    </div>
  );
};

export default ScrollText;
