import React, { useRef } from 'react';
import './Smoke.css'; // Ovo će biti CSS za animaciju

const SmokeBackground = () => {
  const smokeContainerRef = useRef(null);

  // Opcionalno: Možeš koristiti useEffect

  return (
    <div className="smoke--container" ref={smokeContainerRef}>
      <div className="smoke"></div>
      <div className="smoke"></div>
      <div className="smoke"></div>
      <div className="smoke"></div>
      <div className="smoke"></div>
      <div className="smoke"></div>
    </div>
  );
};

export default SmokeBackground;