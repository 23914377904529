import React, { useState, useEffect } from 'react';
import SmokeBackground from './Smoke.js';
import Card from './Card.js';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import '../i18n.js';

export default function Nastim() {
    const { t } = useTranslation(); 
    const [data, setData] = useState([]);  // Stanje za čuvanje podataka
    const [error, setError] = useState(null);  // Stanje za grešku
    const [scrollY, setScrollY] = useState(0);  // Praćenje skrola

    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleResize = () => {
                setViewportHeight(window.innerHeight);
            };
     
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
     }, []);

    // Funkcija za praćenje skrola
    const handleScroll = () => {
        setScrollY(window.scrollY);
    };
    
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
     }, []);
     

    useEffect(() => {
        fetch(process.env.PUBLIC_URL + '/sadrzaj/clanovi-tima/podaci.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => setData(data))
            .catch(error => setError(error));
    }, []);  

    if (error) {
        return <div>Error: {error.message}</div>;
    }

   
     
  
    const cloudStyle1 = {
        transform: `translateY(-${scrollY * 1}px)`, 
        opacity: scrollY < viewportHeight ? 1 : 0,               
        transition: 'opacity 1.5s ease-out',          
    };
    const cloudStyle2 = {
        transform: `translateY(-${scrollY * 1.2}px)`, 
        opacity: scrollY < viewportHeight ? 1 : 0,               
        transition: 'opacity 2s ease-out',          
    };
    const cloudStyle3 = {
        transform: `translateY(-${scrollY * 1.3}px)`, 
        opacity: scrollY < viewportHeight ? 1 : 0,               
        transition: 'opacity 2.5s ease-out',          
    };

    const nasiClanoviDivStyle = {
        position: scrollY < viewportHeight/2 ? 'fixed' : 'relative', 
        top: scrollY < viewportHeight/2 ? '0' : 'auto', 
        opacity: scrollY < viewportHeight/2 ? 1 : 0,  
        /*transform: `translateY(-${scrollY * 3}px)`, */           
        left: 0,
        right: 0,
        textAlign: 'center',  
        zIndex: 3,           
        transition: 'position 0.5s ease-out',  
    };

    const smokeBackgroundStyle = {
        position: 'fixed',   
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',     
        zIndex: 1,          
        opacity: scrollY < 700 ? 1 : 0,  
        transition: 'opacity 0.5s ease-out',
        pointerEvents: 'none'  
    }

    return (
        <>
            <Helmet>
                <title>{t('Naš tim - ASK Bosna')}</title>
                <link rel="canonical" href={`https://askbosna.ba/nas-tim`} />
                <meta name="description" content="Informacije o našim članovima." />
                <meta name="keywords" content="Airsoft, Klub, Bosna, tim, članovi" />
            </Helmet>

            <main className="main--nastim">
                <div className="scroll--clouds">
                <div className="nasi--clanovi--div" style={nasiClanoviDivStyle}>
                   <h1 className="title">{t('Naši članovi')}</h1>
                   <div className="tim--slika--okvir">
                     <div className="tim--slika"></div>
                   </div>
                   <a href="#1" className="arrow-container">
                     <div className="arrow"></div>
                     <div className="arrow"></div>
                     <div className="arrow"></div>  
                   </a>
                </div>
                </div>
                
                <div className="div--split--clouds layer1" style={cloudStyle1}></div>
                <div className="div--split--clouds layer2" style={cloudStyle2}></div>
                <div className="div--split--clouds layer3" style={cloudStyle3}></div>

                <div className="scnd--bck" id="1">
                    <div className="grid--container">
                        {data.length > 0 ? (
                            data.map((clan) => (
                                <Card 
                                    key={clan.id} 
                                    ime={clan.ime}
                                    slika={clan.slika}
                                    status={clan.status}
                                    clan_od={clan.clan_od}
                                />
                            ))
                        ) : (
                            <p>{t('Učitavanje članova...')}</p>  
                        )}
                    </div>
                </div>
                <div style={smokeBackgroundStyle}>
                  <SmokeBackground />
                </div>
            </main>
        </>
    );
}
