import React, { useState, useEffect } from 'react'
import SmokeBackground from './Smoke.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';

export default function Obavjestenja() {

    const { t } = useTranslation();
    const [content, setContent] = useState("");

    useEffect(() => {
        const currentLanguage = i18n.language;
        fetch(`/sadrzaj/tekst/obavjestenja/obavjestenja-${currentLanguage}.json`)
          .then((response) => response.json())
          .then((data) => setContent(data.paragraph))
          .catch((error) => console.error('Error fetching JSON:', error));
    }, [i18n.language]);

    return (
         
        <>
        
        <Helmet>
        <title>{t('Obavještenja - ASK Bosna')}</title>
        <link rel="canonical" href={`https://askbosna.ba/obavjestenja`} />
        <meta name="description" content="Obavještenja o novim avanturama." />
        <meta name="keywords" content="Airsoft, Klub, Bosna, obavjestenja" />
        </Helmet>

      <main className="news--main">
          <h1 className="title">{t('Obavještenja')}</h1>
          <p dangerouslySetInnerHTML={{
                        __html: content || t('Trenutno nema novih obavještenja.'),
                    }} 
                    className="news"
                    />
          <SmokeBackground />
      </main>

      </>

    )
}